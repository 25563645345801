<template>
  <CRow>
    <CCol sm="12">

      <CCard>
        <CCardHeader>
          Daten des Tageskalendar-Eintrags
        </CCardHeader>

        <CCardBody>
          <table class="table table-sm" v-if="tageskalender">
            <tbody>
                <tr>
                  <td class="font-weight-bold">
                    Datum:
                  </td>
                  <td>
                    {{tageskalender.datum|moment('DD.MM.YYYY')}}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    Kunde:
                  </td>
                  <td>
                    {{tageskalender.kunde.name1}}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    Projekt:
                  </td>
                  <td>
                    <span v-if="tageskalender.projekt">
                      {{tageskalender.projekt.name}}
                    </span>
                    <span v-else>-</span>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    Tätigkeit:
                  </td>
                  <td>
                    {{tageskalender.taetigkeit}}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    Team-Mitglied:
                  </td>
                  <td>
                    {{tageskalender.mitarbeiter.name}}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    Dauer:
                  </td>
                  <td>
                    {{tageskalender.dauer|number('0.00')}} Std.
                  </td>
                </tr>
            </tbody>
          </table>

        </CCardBody>
      </CCard>

    </CCol>

    <CCol sm="12" v-if="tageskalender">
      <CCard>

        <CCardHeader>
          Zusätzliche benötigte Daten für das Tagesprotokoll
        </CCardHeader>

        <CCardBody>
          <CRow>
            <CCol sm="12">
              <h5 class="font-weight-bold" style="font-size: 1rem;">Grunddaten</h5>
            </CCol>
          </CRow>

          <CRow class="mt-3">
            <CCol sm="5">
              Protokoll für Kunde
              <em>{{tageskalender.kunde.name1}}</em>
            </CCol>
            <CCol sm="3">
              <Abgeschlossen v-model="abgeschlossen"/>
            </CCol>
            <CCol sm="4">
              <Leistung v-model="leistung"/>
            </CCol>
          </CRow>

          <CRow class="mt-3">
            <CCol sm="12">
              <Material v-model="material" :material-vorgabe="materialVorgabe"/>
            </CCol>
          </CRow>

          <CRow class="mt-5">
            <CCol sm="12">
              <Arbeitsbeschreibung v-model="arbeitsbeschreibung"/>
            </CCol>
          </CRow>

          <CRow class="mt-5">
            <CCol sm="12">
              <CButton
                :color="btnColor()"
                class="btn-protokoll-submit btn-block font-weight-bold pt-3 pb-3"
                :disabled="canSubmit()"
                v-on:click="newEntry()"
              >
                <CIcon name="cil-library-add"/>
                &nbsp;
                Tageskalender-Eintrag in Protokoll Konvertieren
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>

      </CCard>

    </CCol>

  </CRow>
</template>

<script>
import Vue from 'vue'
import Leistung from '@/components/LeistungsAuswahl'
import Abgeschlossen from '@/views/Protokoll/components/Abgeschlossen'
import Material from '@/views/Protokoll/components/Material'
import Arbeitsbeschreibung from '@/views/Protokoll/components/Arbeitsbeschreibung'
import moment from 'moment'

export default {
  name: 'tageskalender-copy',
  components: {
    Leistung,
    Abgeschlossen,
    Material,
    Arbeitsbeschreibung
  },
  props: {
    tageskalenderid: String
  },
  mounted () {
    this.setDefaults()
    if (this.tageskalenderid) {
      Vue.axios.get('/tageskalender/details/' + this.tageskalenderid)
        .then((response) => {
          this.tageskalender = response.data
          this.arbeitsbeschreibung = this.tageskalender.taetigkeit
        })
    }
  },
  data () {
    return {
      imgserver: process.env.VUE_APP_MEDIA_BASEURL,
      tageskalender: null,
      leistung: null,
      abgeschlossen: false,
      arbeitsbeschreibung: '',
      material: [],
      materialVorgabe: [],
      materialVorgabeDefault: [
        {
          id: 1,
          stueckzahl: 1,
          artikelnr: '-',
          material: 'Klein-/Verdrahtungs-/u. Befestigungsmaterial'
        },
        {
          id: 2,
          stueckzahl: 1,
          artikelnr: '-',
          material: 'Anteilige Fahrzeugpauschale'
        }
      ]
    }
  },
  computed: {
    user () {
      return this.$store.getters['security/user']
    }
  },
  watch: {
  },
  methods: {
    setDefaults () {
      this.leistung = null
      this.abgeschlossen = false
      this.arbeitsbeschreibung = ''
      this.material = []
      this.materialVorgabe = this.materialVorgabeDefault
    },
    canSubmit: function () {
      let disabled = false
      if (!this.leistung) {
        disabled = true
      }
      if (this.arbeitsbeschreibung.length === 0) {
        disabled = true
      }
      if (this.material.length === 0 && this.materialVorgabe.length === 0) {
        disabled = true
      }
      return disabled
    },
    btnColor: function () {
      if (!this.canSubmit()) {
        return 'success'
      } else {
        return 'danger'
      }
    },
    newEntry: async function () {
      const self = this
      const tageskalender = this.tageskalender

      // An diesem Punkt sollte das Formular validiert und fertig sein
      let projektid = ''
      if (this.tageskalender.projekt) {
        projektid = this.tageskalender.projekt.projektid
      }
      Vue.axios.post('/protokoll/post', {
        datum: this.dateFormatter(this.tageskalender.datum),
        arbeitsbeschreibung: this.arbeitsbeschreibung,
        tageskalender: true,
        abgeschlossen: this.abgeschlossen,
        kunde: this.tageskalender.kunde.kundenid,
        projekt: projektid,
        leistung: this.leistung
      }).then((response) => {
        const protokollid = response.data.protokollid
        // Alle Materialien speichern
        this._.forEach(this.materialVorgabe, function (m) {
          Vue.axios.post('/material/post', {
            protokoll: protokollid,
            stueck: m.stueckzahl,
            artikelnr: m.artikelnr,
            material: m.material
          })
        })
        this._.forEach(this.material, function (m) {
          Vue.axios.post('/material/post', {
            protokoll: protokollid,
            stueck: m.stueckzahl,
            artikelnr: m.artikelnr,
            material: m.material
          })
        })
        // Alle Arbeitszeiten speichern
        Vue.axios.post('/arbeitszeit/post', {
          protokoll: protokollid,
          mitarbeiter: this.tageskalender.mitarbeiter.mitarbeiterid,
          datum: this.dateFormatter(this.tageskalender.datum),
          dauer: this.tageskalender.dauer,
          taetigkeit: this.tageskalender.taetigkeit
        }).then(async (response) => {
          // Bei automatischen Einträgen die Bilder übertragen und den Tageskalender-Eintrag löschen
          // Bei neuen Einträgen ggf. die Bilder speichern
          try {
            await self.uploadImages(response, tageskalender)
          } catch (e) {
            console.log(e)
            return
          }
          // Originären Tageskalender-Eintrag löschen
          await self.deleteTageskalenderEntry(tageskalender)
        })

        // Formular leeren / Defaults
        this.setDefaults()
        const datum = this.dateFormatter(this.tageskalender.datum)
        this.$router.push({ path: `/tageskalender/uebersicht/${datum}` })
        this.$snotify.success('Neues Protokoll wurde gespeichert, Tageskalender-Eintrag gelöscht.', {
          position: 'rightTop',
          timeout: 4000
        })
      })
    },
    async deleteTageskalenderEntry (tageskalender) {
      await Vue.axios.delete('/tageskalender/delete/' + tageskalender.tageskalenderid)
    },
    async uploadImages (response, tageskalender) {
      if (tageskalender.bilder) {
        if (tageskalender.bilder[0]) {
          await this.saveImage(response.data.arbeitszeitid, 'bild01', await this.getBildBlob(0, tageskalender))
        }
        if (tageskalender.bilder[1]) {
          await this.saveImage(response.data.arbeitszeitid, 'bild02', await this.getBildBlob(1, tageskalender))
        }
        if (tageskalender.bilder[2]) {
          await this.saveImage(response.data.arbeitszeitid, 'bild03', await this.getBildBlob(2, tageskalender))
        }
        if (tageskalender.bilder[3]) {
          await this.saveImage(response.data.arbeitszeitid, 'bild04', await this.getBildBlob(3, tageskalender))
        }
        if (tageskalender.bilder[4]) {
          await this.saveImage(response.data.arbeitszeitid, 'bild05', await this.getBildBlob(4, tageskalender))
        }
      }
    },
    async getBildBlob (number, tageskalender) {
      const bildId = tageskalender.bilder[number].bildid
      const bildUrl = '/_/media/bilder/medium/' + bildId
      const bild = await Vue.axios.get(bildUrl, { responseType: 'blob' })
      return bild.data
    },
    dateFormatter (value) {
      if (value) {
        value = moment(value).format('YYYY-MM-DD')
      } else {
        value = null
      }
      return value
    },
    saveImage (arbeitszeitid, selektor, image) {
      if (image) {
        const formData = new FormData()
        formData.append('selektor', selektor)
        formData.append('dbid', arbeitszeitid)
        formData.append('target', 'arbeitszeit')
        formData.append('uploadfile', image)

        Vue.axios.post(
          '/bilder/upload',
          formData,
          {
            headers: {
              'content-type': `multipart/form-data; boundary=${formData._boundary}`
            }
          }
        )
      }
    }
  }
}
</script>

<style lang="scss">
button.btn-protokoll-submit:disabled {
  cursor: default;
}
</style>
